<template>
	<v-card flat color="transparent">
		<v-divider class="mt-2 mb-5" v-if="!hideTitle"></v-divider>
		<v-toolbar flat color="transparent" class="mw-question-toolbar mt-5 mb-3 ml-0" :floating="isMobile"> 
			<span v-if="!hideTitle" v-html="`${question.title}`"></span>
			<v-tooltip right v-if="question.mandatory" open-on-hover>
					<template v-slot:activator="{on}">
						<v-icon class="ml-2" small v-on="on">mdi-asterisk</v-icon>
					</template>
					<span>
						Question is mandatory
					</span>
				</v-tooltip>

			

			<v-spacer></v-spacer>
			<template v-if="question.help_text">
				<v-tooltip left allow-overflow max-width="280">
					<template v-slot:activator="{ on }">
						<v-icon v-on="on">mdi-information-outline</v-icon>
					</template>
					{{ question.help_text }}
				</v-tooltip>
			</template>
		</v-toolbar>
	</v-card>
</template>

<style lang="less">
.mw-question-toolbar {
	.v-toolbar__content {
		padding-left: 0px;
	}
}
</style>
<script>
export default {
	name: "QuestionTitle",
	props: {
		id: { type: String },
	},
	inject: ["isMobile"],
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		hideTitle(){
			return this.question.type == "header" || this.question.hide_title || false;
		}
	},
	components: {},
};
</script>
